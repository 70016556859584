import makeApiRequest from 'utils/api';

'use strict';

/**
 * Get the value of the requested param from the current page url
 * @param {String} s - the requested search query
 * @returns {String}
 */
export function getUrlSearchParam(s) {
    const allQueries = window.location.search.slice(1).split('&');
    let val = '';

    allQueries.forEach((query) => {
        /* istanbul ignore else */
        if (query.indexOf(s) !== -1) {
            val = query.split('=')[1];
        }
    });

    return val;
}

/**
 * Get the value of the requested param from the current page url (this is for Friendly urls)
 * @returns {String}
 */
export function newFriendlyUrlParam() {
    let allQueries = window.location.pathname.split('/');
    allQueries.shift();
    let val = allQueries;

    return val;
}


/**
 * Add/update query param to the current page url
 * @param {String} key - the name of the query param
 * @param {String} val - the value of the query param
 */
// export function setUrlSearchParam(key, val) {
//     const url = window.location.href,
//         replacement = (typeof val === 'undefined' || val === null)
//             ? ''
//             : `&${key}=${encodeURIComponent(val)}`;

//     return url
//         .replace(RegExp(`([?&]${key}(?=[=&#]|$)[^#&]*|(?=#|$))`), replacement)
//         .replace(/^([^?&]+)&/, '$1?');
// }


/**
 * Perform page redirection
 * @param {String} path - the path to which the redirection should happen
 * @param {Boolean} replace - if true then the current url will be replaced in browser history
 */
/* istanbul ignore next */
export function redirect(path, replace) {
    if (replace) {
        window.location.replace(path);
    }
    else {
        window.location.href = path;
    }
}


/**
 * Format a number into groups of threes separated by commas
 * @param {Number} num - the number to be formatted
 * @returns {String}
 */
export function formatNumberIntoThousands(num) {
    let parts = num.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return parts.join('.');
}


/**
 * Get the user's profile data from the api
 * @returns {Object} - jQuery ajax promise object
 */
export function getUserProfileData() {
    return makeApiRequest('users/me', null, 'get', true);
}


/**
 * Get date in the format 'January 2, 2017'
 * @param {String|Number} datetime - either a datetime string or date in ms
 * @returns {String} - the formatted date
 */
export function getFormattedDate(datetime) {

    // need to replace '-' with '/' so that Safari recognizes it
    const date = typeof datetime === 'string' ? new Date(datetime.replace(/-/g, '/')) : new Date(datetime),
        months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];

    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
}

export function DateForFinances(datetime1, datetime2) {

    // need to replace '-' with '/' so that Safari recognizes it
    const date1 = typeof datetime1 === 'string' ? new Date(datetime1.replace(/-/g, '/')) : new Date(datetime1);
    const date2 = typeof datetime2 === 'string' ? new Date(datetime2.replace(/-/g, '/')) : new Date(datetime2),
        months = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];

    return `${months[date1.getMonth()]} ${date1.getDate()} - ${date2.getDate()}`;
}

/**
 * Get hour in the format Interger
 * @param {Number}
 * @returns {String} - the formatted hour (like: 08 PM).
 */
export function getRealHour(hour) {
    const hours = {
        13: '01',
        14: '02',
        15: '03',
        16: '04',
        17: '05',
        18: '06',
        19: '07',
        20: '08',
        21: '09',
        22: '10',
        23: '11'
    };
    return `${hours[hour]} PM`;
}

/**
 * Initiate fb comments
 * @param {jQuery} $container
 */
export function initFBComments($container) {
    $container.append('<div class="fb-comments" data-href="" + window.location.href + "" data-width="100%" data-numposts="3"></div>');
}


/**
 * Set page's metadata
 * @param data
 */
export function setMetaData(data) {
    let $head = $('head');
    $head.find('title').text(`${data.title} | Mundo Lingo`);
    $head.find('meta[name=description]').text(data.description || '');
    $head.find('meta[name=keywords]').text(data.keywords || '');
}

/**
 * Set page's metadata
 * @param data
 */
export function setMetaData2(data) {
    let $head = $('head');
    $head.find('title').text(`Mundo Lingo | ${data.title}'s international meeting point`);
    $head.find('meta[name=description]').text(data.description || '');
    $head.find('meta[name=keywords]').text(data.keywords || '');
}

export function scrollToBottom(fn) {
    $(window).scroll(() => {
        if (($(window).scrollTop() + $(window).height()) >= ($(document).height() - 50)) {
            fn();
        }
    });
}


/**
 * @param {int} number - the concept number
 * @returns {String} the concept itself as string
 */
export function getSupplierConcept(number) {

    const concept = {
        1: 'Receive Bitcoin',
        2: 'Receive Cash',
        3: 'Sell Bitcoin',
        4: 'Pay for items',
        5: 'Print Shop',
        6: 'Delivery Company',
        7: 'Supplies/Stationary/Others',
    };
    return `${concept[number]}`;

}


/**
 * @param {String} string - the string to be converted
 * @returns {String} the string converted (without accents)
 */
export function removeAccents(string) {
    const acc = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U' };
    return string.split('').map((str) => acc[str] || str).join('').toString();
}

export function round(value, precision) {
    let multiplier = Math.pow(10, precision || 0);
    return Math.round(value * multiplier) / multiplier;
}

export function abbrNum(number, decPlaces) {
    // 2 decimal places => 100, 3 => 1000, etc
    decPlaces = Math.pow(10, decPlaces);

    // Enumerate number abbreviations
    let abbrev = ['k', 'm', 'b', 't'];

    // Go through the array backwards, so we do the largest first
    for (let i = abbrev.length - 1; i >= 0; i--) {

        // Convert array index to "1000", "1000000", etc
        let size = Math.pow(10, (i + 1) * 3);

        // If the number is bigger or equal do the abbreviation
        if (size <= number) {
            // Here, we multiply by decPlaces, round, and then divide by decPlaces.
            // This gives us nice rounding to a particular decimal place.
            number = Math.round(number * decPlaces / size) / decPlaces;

            // Handle special case where we round up to the next abbreviation
            if ((number == 1000) && (i < abbrev.length - 1)) {
                number = 1;
                i++;
            }

            // Add the letter for the abbreviation
            number += abbrev[i];

            // We are done... stop
            break;
        }
    }

    return number;
}

export function dateForMeets(frecuency, date, hour) {

    let finalText = '';
    let dayOfWeek = '';
    let finalHour = '';
    let finalMonth = '';
    let dateObj = new Date(date.replace(/-/g, '/'));
    let today = new Date();

    // returns 0-6 (day of the week in number)
    let dayNumber = dateObj.getDay();

    // returns the day of the month (1-31)
    let dayOfMonthNumber = dateObj.getDate();

    // returns the month (1-12)
    let monthNumber = dateObj.getMonth();

    let year = dateObj.getFullYear();

    let dateFinal = `${dayOfMonthNumber}-${monthNumber + 1}-${year}`;

    // getting the day of week
    switch (dayNumber) {
        case 0:
            dayOfWeek = 'Sunday';
            break;

        case 1:
            dayOfWeek = 'Monday';
            break;

        case 2:
            dayOfWeek = 'Tuesday';
            break;

        case 3:
            dayOfWeek = 'Wednesday';
            break;

        case 4:
            dayOfWeek = 'Thursday';
            break;

        case 5:
            dayOfWeek = 'Friday';
            break;

        case 6:
            dayOfWeek = 'Saturday';
            break;
    }

    // getting the month
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

    finalMonth = months[monthNumber];

    // getting the exact hour
    const hours = {
        '7:00:00': '7 AM',
        '8:00:00': '8 AM',
        '9:00:00': '9 AM',
        '10:00:00': '10 AM',
        '11:00:00': '11 AM',
        '12:00:00': '12 PM',
        '13:00:00': '1 PM',
        '14:00:00': '2 PM',
        '15:00:00': '3 PM',
        '16:00:00': '4 PM',
        '17:00:00': '5 PM',
        '18:00:00': '6 PM',
        '19:00:00': '7 PM',
        '20:00:00': '8 PM',
        '21:00:00': '9 PM',
        '22:00:00': '10 PM',
        '23:00:00': '11 PM',
    };
    finalHour = `${hours[hour]}`;

    // getting frecuency and final text to return
    switch (frecuency) {
        case 'O':
            if (today > dateObj) {
                finalText = 'One time, ' + dayOfWeek + ' ' + dateFinal + ' at ' + finalHour + ' (ended)';
            }
            else {
                finalText = 'One time, this ' + dayOfWeek + ' ' + dateFinal + ' at ' + finalHour;
            }
            break;

        case 'D':
            if (today > dateObj) {
                finalText = 'Every day, started this last ' + dayOfWeek + ' ' + dateFinal + ' at ' + finalHour;
            }
            else {
                finalText = 'Every day, starting this ' + dayOfWeek + ' ' + dateFinal + ' at ' + finalHour;
            }
            break;

        case 'W':
            if (today > dateObj) {
                finalText = 'Every ' + dayOfWeek + ' at ' + finalHour;
            }
            else {
                finalText = 'Every ' + dayOfWeek + ' at ' + finalHour + ', starting this ' + dateFinal;
            }

            break;

        case 'M':
            if (today > dateObj) {
                finalText = 'Every ' + dayOfMonthNumber + 'th of the month at ' + finalHour;
            }
            else {
                finalText = 'Every ' + dayOfMonthNumber + 'th of the month, starting this ' + dateFinal + ' at ' + finalHour;
            }
            break;

        case 'Y':
            finalText = 'Once per year, every ' + dayOfMonthNumber + 'th of ' + finalMonth + ' at ' + finalHour;
            break;
    }

    return finalText;
}

