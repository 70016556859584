import HeaderNav from 'components/header-nav';

'use strict';

const LandingView = {
    /**
     * Initialize the view
     */
    init() {

        $.material.init();
        $('body').removeClass('hidden');
        HeaderNav.init();

        /* new - classic remodal */
        this.classicNew = $('#classic-new-page');
        this.classicRemodal = this.classicNew.remodal({ hashTracking: false });
        this.bindEvents();
        this.classicRemodal.open();
    },

    closeNewPageRemodal(e) {
        e.preventDefault();
        this.classicRemodal.close();
    },

    bindEvents() {
        $(document).on('click', '.js-close-popup-new-page', this.closeNewPageRemodal.bind(this));
    }
};

/* start-test */
/* istanbul ignore if  */
if (!window.__karma__) {
    /* end-test */
    LandingView.init();
    /* start-test */
}

/* end-test */
export default LandingView;
